import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

import Card from "src/components/atoms/Card/Card";
import LikeIcon from "src/components/atoms/Icons/LikeIcon";
import BookMarkIcon from "src/components/atoms/Icons/BookMarkIcon";
import InfoIcon from "src/components/atoms/Icons/InfoIcon";
import exerciseApis from "src/api/exercise";
import { trackEvent } from "src/analytics";
import { EVENT_NAMES } from "src/analytics/events";

interface ExerciseVideoCardProps {
  videoUrl: string;
  border: boolean;
  title: string;
  tags: string[];
  description: string;
  likeCount: number;
  bookMarked: boolean;
  liked: boolean;
  onInfoClick: () => void;
  fullWidth?: boolean;
  thumbnail_url?: string;
  content_master_id: string;
  genre: string;
}

const ExerciseVideoCard: React.FC<ExerciseVideoCardProps> = (props) => {
  const {
    videoUrl,
    title,
    tags,
    description,
    likeCount: lc,
    bookMarked: bm,
    border,
    onInfoClick,
    liked,
    fullWidth = false,
    thumbnail_url = "https://images.healthshots.com/healthshots/en/uploads/2022/05/11184715/Yoga-for-weight-loss-1600x900.jpg",
    content_master_id,
    genre,
  } = props;

  const theme = useSelector((state: RootState) => state.theme);

  const [likeCount, setLikeCount] = useState<number>(lc || 0);
  const [bookMark, setBookMark] = useState<boolean>(bm || false);
  const [userLiked, setUserLiked] = useState<boolean>(liked || false);
  const [playing, setPlaying] = useState<boolean>(false);

  const playerRef = useRef<ReactPlayer | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handlePlay = () => {
    trackEvent(EVENT_NAMES.PLAY_EXERCISE_VIDEO, {
      video_name: title,
    });
    setPlaying(true);
    setTimeout(() => {
      if (containerRef.current) {
        try {
          if (containerRef.current.requestFullscreen) {
            containerRef.current.requestFullscreen();
          } else if ((containerRef as any).current.webkitRequestFullscreen) {
            (containerRef as any).current.webkitRequestFullscreen();
          } else if ((containerRef as any).current.msRequestFullscreen) {
            (containerRef as any).current.msRequestFullscreen();
          }
        } catch (error) {
          console.error("Fullscreen API not supported or failed", error);
        }
      }
    }, 50);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setPlaying(false);
        if (playerRef.current) {
          playerRef.current.seekTo(0);
        }
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const onPressLike = async () => {
    try {
      const payload = {
        content_master_id,
        is_active: userLiked ? "N" : "Y",
        extra: { content_master_id, genre },
      };
      await exerciseApis.toggleVideoLike(payload);

      setLikeCount((prevCount) => prevCount + (userLiked ? -1 : 1));
      setUserLiked(!userLiked);
    } catch (error) {
      console.error("Error toggling like status:", error);
    }
  };

  const onPressBookmark = async () => {
    const payload = {
      content_master_id,
      is_active: bookMark ? "N" : "Y",
      extra: { content_master_id, genre },
    };
    await exerciseApis.toggleVideoBookmark(payload);
    setBookMark((prev) => !prev);
  };

  return (
    <Card
      className={`p-[0px] rounded-xl flex-shrink-0 ${
        border && "border-[1px] border-primary"
      } ${fullWidth ? "w-[100%]" : "w-[90%]"}`}
    >
      <div className="h-[100px] bg-black w-full overflow-hidden rounded-t-xl">
        {!playing ? (
          <div className="relative inline-block w-full" onClick={handlePlay}>
            <img
              className="object-contain w-full"
              src={thumbnail_url}
              alt="Thumbnail"
            />
            <div
              className="bg-black bg-opacity-30 rounded-full w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
              style={{
                background:
                  "radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%)",
              }}
            >
              <div
                className="ml-[7px] border-solid"
                style={{
                  borderWidth: "16px 0 16px 26px",
                  borderColor: "transparent transparent transparent white",
                  borderStyle: "solid",
                }}
              ></div>
            </div>
          </div>
        ) : (
          <div
            ref={containerRef}
            className="relative w-full cursor-pointer h-[100px] rounded-xl"
          >
            <ReactPlayer
              ref={playerRef}
              url={videoUrl}
              width="100%"
              height="100%"
              controls={playing}
              playing={playing}
              className="rounded-lg"
              stopOnUnmount
            />
          </div>
        )}
      </div>
      <div className="h-full px-2">
        <h1 className="text-[14px] py-1 font-semibold text-primary">{title}</h1>
        <ul className="flex flex-shrink-0 gap-3 overflow-x-auto overflow-y-hidden">
          {tags.map((tag, index) => (
            <li
              key={index}
              className="flex-shrink-0 bg-background-secondary px-3 py-1 text-xs rounded-lg text-text-primary"
            >
              {tag}
            </li>
          ))}
        </ul>
        <p className="text-xs my-2 text-text-subtitle line-clamp-2">
          {description}
        </p>
        <div className="flex gap-2 py-1 items-center">
          <div className="flex items-center space-x-[4px]">
            {!userLiked ? (
              <button onClick={onPressLike} className="flex items-center">
                <div className="w-6 h-6">
                  <LikeIcon color={theme.colors["--color-primary"]} />
                </div>
              </button>
            ) : (
              <button onClick={onPressLike} className="flex items-center">
                <div className="w-6 h-6">
                  <LikeIcon color={theme.colors["--color-primary"]} filled />
                </div>
              </button>
            )}
            {!!likeCount && (
              <p className="text-sm text-text-subtitle">{likeCount}</p>
            )}
          </div>
          <button
            onClick={onPressBookmark}
            className="flex items-center w-4 h-4"
          >
            {!bookMark ? (
              <BookMarkIcon color={theme.colors["--color-primary"]} />
            ) : (
              <BookMarkIcon color={theme.colors["--color-primary"]} filled />
            )}
          </button>
          <button onClick={onInfoClick} className="flex items-center w-4 h-4">
            <InfoIcon color={theme.colors["--color-primary"]} />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ExerciseVideoCard;
