import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useQueryParams from "src/hooks/useQueryParams";

import Card from "src/components/atoms/Card/Card";
import ExpandableDivs from "src/components/atoms/Layout/ExpandingLayout";
import BarChart from "src/components/molecules/chart/Chart";
import { Header } from "src/components/molecules/Header";
import DayMonthHeader from "src/components/molecules/Insights/DayMonthHeader";
import CircularProgress from "src/components/atoms/CircularProgress/CircularProgress";
import BottomSheetSelect from "src/components/atoms/BottomSheetSelect";
import GoalSelectionBottomSheet from "src/components/molecules/Insights/GoalSelectionBottomSheet";
import SpinnerLoader from "src/components/atoms/Loader/SpinnerLoader";

import { insightsFilterOptions } from "src/constants/insights";
import { RootState } from "src/store";
import {
  caloriesAdherenceRequest,
  getDietPlanByDateRequest,
} from "src/store/slices/foodDiarySlice";
import { foodDiary } from "src/types/index";

const macronutrient = ["fats", "carbs", "protein"];

const DietInsightsPage: React.FC = () => {
  const [selectedDateOption, setSelectedDateOption] = useState<string>(
    insightsFilterOptions[0].value
  );
  const [selectedGoal, setSelectedGoal] = useState<number>(0);
  const [showGoalBottomSheet, setShowGaolBottomSheet] = useState(false);
  const queryParams = useQueryParams();

  const { user } = useSelector((state: RootState) => state.auth);
  const { dietPlan, caloriesAdherence } = useSelector(
    (state: RootState) => state.foodDiary
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const date = queryParams?.date || moment(new Date()).format("YYYY-MM-DD");
  const isPaidUser = !!user.data.paidUser;

  const goalValue = useMemo(() => {
    const tempSelectedGoal = !!dietPlan.data?.health_coach_id
      ? // @ts-ignore
        dietPlan.data?.total_calories
      : // @ts-ignore
        dietPlan.data?.default_calories;
    setSelectedGoal(tempSelectedGoal);
    return tempSelectedGoal;
  }, [dietPlan]);

  // check user is free or not
  //   useEffect(() => {
  //     const userPlan = checkUserPlanFree(user?.data?.patient_plans);
  //     setFreeUserPlan(userPlan);
  //   }, [user?.data?.patient_plans]);

  useEffect(() => {
    fetchData(selectedDateOption);
  }, [selectedDateOption]);

  const fetchData = (selectedDateOption: any) => {
    //  caloriesAdherence data
    const payload = {
      date: moment(date ?? new Date()).format("YYYY-MM-DD"),
      // date: "2024-11-27",
    };
    new Promise((resolve, reject) => {
      dispatch(
        getDietPlanByDateRequest({
          payload,
          resolve,
          reject,
        })
      );
    })
      .then((res: any) => {
        if (Number(res?.code) === 1 || Number(res?.code) === 2) {
          const payload = {
            patient_id: user.data.patientID,
            taken_time: selectedDateOption,
            todays_date: date,
          };
          // showLoader();
          new Promise((resolve, reject) => {
            dispatch(
              caloriesAdherenceRequest({
                payload,
                resolve,
                reject,
              })
            );
          }).finally(() => {
            //   hideLoader();
          });
          // setIsFetchingData(false);
          // getFoodConsumptionByDate();
        } else {
          // setIsFetchingData(false);
        }
      })
      .catch(() => {
        //   setIsFetchingData(false);
      })
      .finally(() => {
        //   setIsFetchingData(false);
      });
  };

  const getDateRange = () => {
    const selectedDate = date ?? new Date();
    switch (selectedDateOption) {
      case "1D":
        return moment(selectedDate).diff(moment(), "days") === 0
          ? "Today"
          : moment(selectedDate).format("DD MMM YYYY");
      case "7D":
        return `${moment(selectedDate)
          .subtract(6, "days")
          .format("DD MMM")} - ${moment(selectedDate).format("DD MMM")}`;
      case "1M":
        // case '30D':
        return `${moment(selectedDate)
          .subtract(1, "months")
          .format("DD MMM")} - ${moment(selectedDate).format("DD MMM YYYY")}`;
      case "6M":
        return `${moment(selectedDate)
          .subtract(6, "months")
          .format("DD MMM")} - ${moment(selectedDate).format("DD MMM YYYY")}`;
      case "1Y":
        return `${moment(selectedDate)
          .subtract(11, "months")
          .format("MMM YYYY")} - ${moment(selectedDate).format("MMM YYYY")}`;
    }
  };

  const renderCAMItem = (item: foodDiary.MealCalories, index: number) => {
    const value = isNaN(
      parseFloat(item.achieved_calories.toString()) /
        parseFloat(item.target_calories)
    )
      ? 0
      : Math.round(
          (parseFloat(item.achieved_calories.toString()) /
            parseFloat(item.target_calories)) *
            100
        );
    return (
      <div className="flex items-center gap-4">
        <div>
          <CircularProgress
            value={value}
            text={`${value}%`}
            className="w-10 h-10"
          />
        </div>
        <div>
          <div className="text-base text-text-title font-semibold capitalize">
            {item.title}
          </div>
          <p className="text-xs text-text-subtitle">
            <span className="text-xs font-semibold text-text-title">
              {isNaN(parseFloat(item?.achieved_calories.toString()))
                ? 0
                : Math.round(parseFloat(item?.achieved_calories.toString()))}
            </span>
            <span> of </span>
            <span>
              {isNaN(parseFloat(item?.target_calories))
                ? 0
                : Math.round(parseFloat(item?.target_calories))}
            </span>
            <span> Cal</span>
          </p>
        </div>
      </div>
    );
  };

  const renderMacroNutrientAnalysisItem = (
    item: foodDiary.MacronutritionCalories,
    index: number
  ) => {
    const value = isNaN(
      parseFloat(item.achieved_value) / parseFloat(item.goal_value)
    )
      ? 0
      : Math.round(
          (parseFloat(item.achieved_value) / parseFloat(item.goal_value)) * 100
        );
    return (
      <div className="flex items-center gap-4">
        <div>
          <CircularProgress
            value={value}
            text={`${value}%`}
            className="w-10 h-10"
          />
        </div>
        <div>
          <div className="text-base text-text-title font-semibold capitalize">
            {item.title}
          </div>
          <p className="text-xs text-text-subtitle">
            <span className="text-xs font-semibold text-text-title">
              {isNaN(parseFloat(item.achieved_value))
                ? 0
                : Math.round(parseFloat(item.achieved_value))}
            </span>
            <span> of </span>
            <span>
              {isNaN(parseFloat(item?.goal_value))
                ? 0
                : Math.round(parseFloat(item?.goal_value))}
            </span>
            <span> g</span>
          </p>
        </div>
      </div>
    );
  };

  const macronutrient_analysis = useMemo(() => {
    const {
      carbs_achieved,
      carbs_target,
      fats_achieved,
      fats_target,
      protein_achieved,
      protein_target,
    } = caloriesAdherence.data.macronutrition_analysis;
    return macronutrient.map((item) => {
      let achieved_value;
      let goal_value;
      switch (item) {
        case "fats":
          achieved_value = fats_achieved;
          goal_value = fats_target;
          break;
        case "carbs":
          achieved_value = carbs_achieved;
          goal_value = carbs_target;
          break;
        case "protein":
          achieved_value = protein_achieved;
          goal_value = protein_target;
          break;
        default:
          achieved_value = 0;
          goal_value = 0;
          break;
      }
      return {
        title: item,
        achieved_value: achieved_value?.toString(),
        goal_value: goal_value?.toString(),
      };
    });
  }, [caloriesAdherence.data.macronutrition_analysis]);

  return (
    <div className="bg-background">
      <ExpandableDivs
        showExpandBtn={false}
        component1={
          <div className="">
            <Header
              title="Diet Insights"
              onClickOfBackBtn={() => navigate(`/diet?date=${date}`)}
            />
            <DayMonthHeader
              selectedDateOption={selectedDateOption}
              setSelectedDateOption={(item) => setSelectedDateOption(item)}
            />
          </div>
        }
        component2={
          caloriesAdherence?.isLoading ? (
            <div className="h-full flex items-center justify-center">
              <SpinnerLoader />
            </div>
          ) : (
            <div className="px-4">
              <div>
                <p>
                  <span className="text-sm text-text-subtitle">
                    {selectedDateOption === "1D" ? "Total" : "Average"}
                  </span>
                  <br />
                  <span className="text-2xl font-semibold text-black">
                    {Math.round(
                      Number(
                        caloriesAdherence?.data?.totalAchievedCalories ?? 0
                      )
                    )}
                  </span>{" "}
                  <span className="text-sm text-text-subtitle">calories</span>
                  <br />
                  <span className="text-sm text-text-subtitle">
                    {getDateRange()}
                  </span>
                </p>
              </div>

              <BarChart
                className="mt-4"
                data={{
                  x: caloriesAdherence.data.finalData?.map(
                    (calories) => calories.x_value
                  ),
                  y: caloriesAdherence.data.finalData.map(
                    (calories) => calories.achieved_calories
                  ),
                }}
                goalValue={selectedGoal}
              />

              <Card className="mt-4 !p-0">
                <button
                  className="p-4 w-full flex items-center justify-between"
                  disabled={isPaidUser}
                  onClick={() => setShowGaolBottomSheet(true)}
                >
                  <p className="text-sm font-semibold text-text-title">Goal</p>
                  <p className="text-text-subtitle">
                    {isNaN(selectedGoal) ? 0 : Math.round(selectedGoal)}
                    {!isPaidUser ? (
                      <i className="fa fa-solid fa-chevron-right pl-2 text-md" />
                    ) : null}
                  </p>
                </button>
              </Card>

              {caloriesAdherence.data.calories_accross_meals.length > 0 && (
                <div>
                  <h3 className="text-base text-text-title font-semibold py-4">
                    Calories Across Meals
                  </h3>
                  <Card className="p-4 flex flex-col gap-4">
                    {caloriesAdherence.data.calories_accross_meals.map(
                      renderCAMItem
                    )}
                  </Card>
                </div>
              )}

              {macronutrient_analysis.length > 0 && (
                <div>
                  <h3 className="text-base text-text-title font-semibold py-4">
                    Macronutrient Analysis
                  </h3>
                  <Card className="p-4 flex flex-col gap-4">
                    {macronutrient_analysis.map(
                      renderMacroNutrientAnalysisItem
                    )}
                  </Card>
                </div>
              )}
              <section className="mb-4">
                <h3 className="text-base text-text-title font-semibold py-4">
                  About Calories
                </h3>
                <Card className="p-4">
                  <p className="text-text-subtitle text-sm">
                    A calorie is a unit measuring energy in food. Our bodies use
                    calories for activities. Knowing and balancing calorie
                    intake helps maintain a healthy lifestyle.
                  </p>
                </Card>
              </section>
            </div>
          )
        }
      />

      <BottomSheetSelect
        open={showGoalBottomSheet}
        close={() => setShowGaolBottomSheet(false)}
      >
        <GoalSelectionBottomSheet
          selectedGoal={String(selectedGoal)}
          closeBottomSheet={() => {
            setShowGaolBottomSheet(false);
            fetchData(selectedDateOption);
          }}
        />
      </BottomSheetSelect>
    </div>
  );
};

export default DietInsightsPage;
